import { type ReactNode } from 'react';
import Image from 'next/image';

import clsx from 'clsx';

import { imgPrefix } from '../../../utils';

import styles from './Status.module.scss';

import { type TPolymorphicProps } from '../../../types/utils';

export type TStatus =
  | 'finalized'
  | 'waiting'
  | 'searching'
  | 'finalized_zero_lead'
  | 'blocked'
  | 'notification';

type TStatusProps<T extends React.ElementType> = TPolymorphicProps<T> & {
  children: ReactNode;
};

const Root = <T extends React.ElementType = 'div'>({
  as,
  children,
}: TStatusProps<T>) => {
  const StatusComponent = as || 'div';

  return (
    <StatusComponent className={styles.status__root}>
      {children}
    </StatusComponent>
  );
};

type TStatusTitle<T extends React.ElementType> = TPolymorphicProps<T> & {
  status: TStatus;
  remainingHours?: number | null;
};

const Title = <T extends React.ElementType = 'span'>({
  as,
  status,
  remainingHours,
}: TStatusTitle<T>) => {
  const StatusTitleComponent = as || 'span';

  const statusTitle = {
    finalized: 'Busca finalizada',
    waiting: 'Em Análise',
    searching: `Buscando (${remainingHours ? remainingHours : '0'}h restantes)`,
    finalized_zero_lead: 'Busca finalizada',
    blocked: 'Pedido Bloqueado',
    notification: 'Novas mensagens',
  };

  const statusIcon = {
    finalized: 'finalized',
    waiting: 'waiting',
    searching: 'searching',
    finalized_zero_lead: 'finalized_zero_lead',
    blocked: 'blocked',
    notification: 'finalized_zero_lead',
  };

  const statusTitleClassname = clsx(
    styles.status__title,
    styles[`status__title--${status}`]
  );

  return (
    <StatusTitleComponent className={statusTitleClassname}>
      <Image
        src={imgPrefix(`/images/orders/status/${statusIcon[status]}.svg`)}
        alt=""
        height={20}
        width={20}
        quality={70}
      />

      {statusTitle[status]}
    </StatusTitleComponent>
  );
};

type TStatusDescription<T extends React.ElementType> = TPolymorphicProps<T> & {
  status: TStatus;
  totalProfessionalsFound?: number | null;
};

const Description = <T extends React.ElementType = 'p'>({
  as,
  status,
  totalProfessionalsFound = null,
}: TStatusDescription<T>) => {
  const StatusDescriptionComponent = as || 'p';

  const shouldShowTotalProfessionalsFound =
    ['finalized', 'searching', 'notification'].includes(status) &&
    totalProfessionalsFound !== 0;

  function getProfessionalFoundDescription() {
    if (totalProfessionalsFound === 1) {
      return ' profissional encontrado';
    }

    return ' profissionais encontrados';
  }

  function getSearchingDescription() {
    if (!totalProfessionalsFound) {
      return 'Aguarde, estamos buscando profissionais';
    }

    return getProfessionalFoundDescription();
  }

  const statusDescription = {
    finalized: getProfessionalFoundDescription(),
    waiting:
      'Aguarde, estamos analisando seu pedido antes de iniciarmos a busca por profissionais',
    searching: getSearchingDescription(),
    finalized_zero_lead:
      'Dessa vez não encontramos profissionais, mas você pode tentar solicitar novamente.',
    blocked:
      'Seu pedido violou alguma de nossas regras, por isso não buscaremos profissionais',
    notification: getProfessionalFoundDescription(),
  };

  return (
    <StatusDescriptionComponent className={styles.status__description}>
      {shouldShowTotalProfessionalsFound && (
        <strong>{totalProfessionalsFound}</strong>
      )}
      {statusDescription[status]}
    </StatusDescriptionComponent>
  );
};

export { Root, Title, Description };
